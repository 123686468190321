import Button from "@components/global/Button/Button";
import Icon from "@components/global/Icon/Icon";
import MainLogo from "@components/global/MainLogo/MainLogo";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { ROUTES_NAME_E } from "@utils/routesName";
import AccountPopover from "../AccountPopover/AccountPopover";
import { Switch } from "antd";
import useMediaQuery from "@utils/hooks/useMediaQuery";
import { SWITCH_NAV_MODE, useHeaderControl } from "./useHeaderControl";

import notif from "@assets/icons/notif.svg";
import dashboard_icon from "@assets/icons/dashboard.svg";

export default function Header() {
  const isTablet = useMediaQuery("max", 991);
  const control = useHeaderControl();

  return (
    <>
      <header className={styles.header}>
        <MainLogo className={styles.logo} />
        {isTablet && (
          <div className={styles.login_wrapper}>
            <div className={styles.switcher}>
              <Switch className={styles.switch} onChange={control.onSwitch} />
              <p className={styles.mode}>{control.nav} mode</p>
            </div>
            <div className={styles.login}>
              {/* <Icon src={notif} /> */}
              {/* <AccountPopover
                email={control.me?.email.value || ""}
                mode={control.nav}
              /> */}
            </div>
          </div>
        )}
        <nav className={styles.links}>
          {control.links.map((link, i) => (
            <Link to={link.to} className={styles.link} key={i}>
              {link.name === "Dashboard" && <Icon src={dashboard_icon} />}
              {link.name}
            </Link>
          ))}
        </nav>
        {!isTablet && (
          <div className={styles.login_wrapper}>
            <div className={styles.switcher}>
              <Switch className={styles.switch} onChange={control.onSwitch} />
              <p className={styles.mode}>{control.nav} mode</p>
            </div>
            <div className={styles.login}>
              {/* <Icon src={notif} /> */}
              {/* <AccountPopover
                email={control.me?.email.value || ""}
                mode={control.nav}
              /> */}
            </div>
          </div>
        )}
      </header>
      <div className={styles.demo_text}>
        Website is under construction. If you would like to become an investor,
        please send us a message using the form at the bottom of this page.
      </div>
    </>
  );
}
